import React, { useState } from 'react'
import logo from '../images/logo.svg'
import { NavLink as Link } from 'react-router-dom'

export default function NavBar() {
  const [showNav, setShowNav] = useState(false);
  function handleShow(e) {
    setShowNav(prev => !prev);
  }

  return (
    <div className='navbar'>
        <Link to='/'><img src={logo} alt='website logo' className='nav-logo'/></Link>
        <div className='navbar-mobile'>
            <button onClick={handleShow} className='expand-button'>
              <span class="material-symbols-outlined">
                menu
              </span>
            </button> 
        </div>
        <ul className={showNav ? 'nav-list-mobile show' : 'nav-list hide'}>
          <li className='nav-links'><Link to='/sobre'>SOBRE MIM</Link></li>
          {/* <li className='nav-links'><Link to='/visualization'>VISUALIZAÇÃO DE INFORMAÇÃO<br></br>COM PYTHON</Link></li> */}
          <li className='nav-links'><Link to='/machine-learning'>MACHINE LEARNING<br></br>DA TEORIA À PRÁTICA</Link></li>
          {/* <li className='nav-links'><Link to='/forum'>FÓRUM</Link></li> */}
        </ul>
    </div>
  )
}
