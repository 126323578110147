import React from 'react'
import { NavLink as Link } from 'react-router-dom'
import profile from '../images/jn.jpg'
import NavBar from './NavBar'
import Footer from './Footer'

export default function AboutMe() {
  return (
    <div className='about-container'>
        <NavBar />
        <div className='about-content'>
            <div className='about-info'>
                <div className="presentation">
                    <h1>Olá, O meu nome é <span className="underlined">José Neto</span> <br/>
                    e este é o meu  <span className="underlined">site</span></h1>
                    <p>Sou mestre em Engenharia Civil e estou neste momento a terminar o meu Doutoramento. <br/>
                    Ao longo do meu percurso desenvolvi trabalhos na área da ferrovia, 
                    dedicados à interação comboio-via, à dinâmica de veículos ferroviários e 
                    à identificação da condição da via com recurso a metodologias baseadas em 
                    sistemas onboard. <br/>
                    Atualmente, trabalho como Data Scientist numa consultora internacional onde 
                    executo projetos para o departamento de Customer Relationship Management.<br/>
                    Neste site podes encontrar todos os cursos que estão disponíveis neste momento.
                    </p>
                </div>
                <img src={profile} alt="authors" />
            </div>
            <ul className='links-container'>
                {/* <li><Link className='course-links' to={'/visualization'}>Curso de Visualização de Dados</Link></li> */}
                <li><Link className='course-links' to={'/machine-learning'}>Curso de Machine Learning - Da Teoria à Prática</Link></li>
            </ul>
        </div>
        <Footer />
    </div>
  )
}
