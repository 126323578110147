import './App.scss';
import NavBar from './components/NavBar';
import { Route, Routes } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Footer from './components/Footer';
import MachineLearning from './components/MachineLearning';
// import DataVisualization from './components/DataVisualization';
import AboutMe from './components/AboutMe';
// import Forum from './components/Forum';
// import ModuleOne from './components/Modules/ModuleOne';
// import ModuleTwo from './components/Modules/ModuleTwo';
// import ModuleThree from './components/Modules/ModuleThree';
// import ModuleFour from './components/Modules/ModuleFour';
// import ModuleFive from './components/Modules/ModuleFive';

function App() {
  AOS.init({
    offset: 200,
    duration: 3000,
  });

  return (
    <div className="App">
      <Routes>
        <Route 
          path='/'
          element={
            <>
            <NavBar />
            <div className='home-container'>
              <div className="line one"></div>
              <div className="line two"></div>
              <div className="name" data-aos='fade-left'><p>JOSÉ</p></div>
                <div className="name" data-aos='fade-right'><p>NETO</p></div>
            </div>
            <Footer />
          </>
          }
        />
        <Route path='/sobre' element={<AboutMe />} />
        <Route path='/machine-learning' element={<MachineLearning/>}/>
        {/* <Route path='/visualization' element={<DataVisualization />}/>⁄ */}
        {/* <Route path='/machine-learning/modulo1' element={<ModuleOne />}/>
        <Route path='/machine-learning/modulo2' element={<ModuleTwo />}/>
        <Route path='/machine-learning/modulo3' element={<ModuleThree />}/>
        <Route path='/machine-learning/modulo4' element={<ModuleFour />}/> */}
        {/* <Route path='/visualization/modulo5' element={<ModuleFive />}/>
        <Route path='/forum' element={<Forum />}/> */}
        <Route path="*" element={<div>Page not found</div>} />
      </Routes>
    </div>
  );
}

export default App;
