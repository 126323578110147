import React from 'react'
// import { NavLink as Link } from 'react-router-dom'
// import Counter from './Counter';
import NavBar from './NavBar'
import Footer from './Footer'
import construction from '../images/underConst.svg'

// import introducao from '../videos/sessao_abertura.mp4';

export default function MachineLearning() {
    // const releaseDate = '2024-04-02T17:00:00';
    // const difference = new Date(releaseDate) - new Date();

  return (
    <div className='ml-container'>
        <NavBar />\


     {/* {difference > 0 ? 
            <Counter releaseDate={releaseDate} /> :
            <div className='ml-content' onContextMenu={(e) => { 
                e.preventDefault(); 
                e.stopPropagation(); 
                return false
                }}>

                <h1>Bem-vindos ao meu curso de Machine Learning - <br></br> Da Teoria à Prática</h1>

                <video preload="auto" width='100%' height='100%' controls controlsList="nodownload" oncontextmenu={(e)=>e.preventDefault()}>
                    <source src={introducao}/>
                </video>

                <ul className='mod-list'>
                    <div>
                        <li className='mod-links'><Link to={'/machine-learning/modulo1'}>Módulo 1</Link></li>
                        <div className='div-line'></div>
                    </div>
                    <div>
                        <li className='mod-links'><Link to={'/machine-learning/modulo2'}>Módulo 2</Link></li>
                        <div className='div-line'></div>
                    </div>
                    <div>
                        <li className='mod-links'><Link to={'/machine-learning/modulo3'}>Módulo 3</Link></li>
                        <div className='div-line'></div>
                    </div>
                    <div>
                        <li className='mod-links'><Link to={'/machine-learning/modulo4'}>Módulo 4</Link></li>
                        <div className='div-line'></div>
                    </div>
                </ul>
            </div>

        }  */}
    <div className="under-construction">
        <img src={construction} alt='illustration of a construction'/>
        <div>
        <h1>Nova edição deste curso em breve</h1>
        </div>
    </div>
    <Footer />
    </div>
  )}
